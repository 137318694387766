<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Transaction Management</h1>
        </v-container>
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="displayList"
              :page.sync="page"
              :items-per-page="rowsPerPage"
              :loading="loading"
              hide-default-footer
              class="elevation-2 mt-4 px-4"
            >
              <template v-slot:[`item.controls`]="{ item }">
                  <v-icon
                    medium
                    color="green"
                    class="mr-2"
                    @click="view(item)"
                  >
                    mdi-magnify-plus
                  </v-icon>
              </template>
              <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning">
                      Found no results.
                  </v-alert>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="numberOfPages"
                :total-visible="7"
              ></v-pagination>
            </div>
          </div>
        </template>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Transaction',
    components: {
        Loading,
        DetailsForm
    },
    watch: {
      page: {
          handler() {
              this.queryData();
          },
          deep: true
      }
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'roles',
            'authorities'
        ]),
    },
    data() {
        return {
            headers: [],
            displayList: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            numberOfPages: 0,
            page: 1,
            rowsPerPage: 5,
            details: {
                title: '',
                item: {}
            },
            query: {
                code: '',
                name: ''
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
        }
    },
    async mounted() {
        this.setHeaders()
        console.log('2')
        this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.page = 1
            }
            let pageNumber = this.page - 1;
            try {
                const response = await API.getTransactions(pageNumber, this.rowsPerPage, this.query)
                if (!response || response.error) {
                    //error getting data
                    console.log(`${response.error}`)
                } else {
                  this.displayList = response.transactions
                  this.numberOfPages = response.pageSize
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        closeDialog(type) {
          console.log(type)
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.$router.go(0);
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        view(item) {
            this.details.title = item.display_name
            this.details.item = item
            this.isShowDetails = true
        },
        search(event, type) {
            this.query[type] = event
        },
        setHeaders() {
            var headers = [{
                    text: 'Reference #',
                    value: 'referenceNumber',
                    align: 'center',
                },
                {
                    text: 'Merchant Code',
                    value: 'merchantCode',
                    align: 'center',
                },
                {
                    text: 'Payment Option',
                    value: 'paymentOption',
                    align: 'center'
                },
                {
                    text: 'Type',
                    value: 'type',
                    align: 'center'
                },
                {
                    text: 'Amount',
                    value: 'amount',
                    align: 'center'
                },
                {
                    text: 'Mobile Number',
                    value: 'mobileNumber',
                    align: 'center'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated',
                    align: 'center'
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center'
                }
            ]
            this.headers = headers
        },
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
